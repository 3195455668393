import React from "react"
import Seo from "./seo"



const Layout = ({ children }) => {
  return (
    <>
      <Seo />
      <div>{children}</div>
    </>
  )
}
export default Layout