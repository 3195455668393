import React from "react"
import { Link } from "gatsby"


const Header = () => {
  return (
    <div className="container-fluid header">
      <div className="row">
        <div className="col-md-3">
          <h1>
            <Link to="/">
              Roberto Rodriguez
              <br />
              Graphic Design(r)
            </Link>
          </h1>
        </div>
        <div className="offset-md-2 col-md-3 d-none d-md-block">
          <h2>
            E:
            <a
              href="mailto:hola@rg-rq.com?subject=hola"
              target="_blank"
              rel="noreferrer"
            >
              &nbsp; hola@rg-rq.com
            </a>
          </h2>
          <h2>Saludos a Monterrey</h2>
        </div>
        <div className="col-md-2 d-none d-md-block">
          <h2>
            <Link to="/">Work ↗</Link>
          </h2>
          <h2>
            <Link to="/memories">Memories ↗</Link>
          </h2>
        </div>
        <div className="col-md-2">
          <h2>
            <Link to="/info">Info ↗</Link>
          </h2>
          <h2>
            <a href="mailto:hola@rg-rq.com" target="_blank" rel="noreferrer">
              Contact ↗
            </a>
          </h2>
        </div>
      </div>
    </div>
  )
}
export default Header
